import { mapGetters } from "vuex";
import { Bar, mixins } from "vue-chartjs";
const { reactiveProp } = mixins;

export default {
  extends: Bar,
  mixins: [reactiveProp],

  computed: {
    ...mapGetters(["layoutConfig"]),

    chartOptions() {
      return {
        responsive: true,
        maintainAspectRatio: false,

        scales: {
          xAxes: [{
            display: true,
            stacked: true,

            scaleLabel: {
              display: false,
            },

            gridLines: false,

            ticks: {
              display: true,
              beginAtZero: true,
              fontColor: this.getBaseColor("shape", 3),
              fontSize: 13,
              padding: 10,
            },
          }],

          yAxes: [{
            display: true,
            stacked: true,
            scaleLabel: {
              display: false,
            },

            gridLines: {
              color: this.getBaseColor("shape", 2),
              drawBorder: false,
              offsetGridLines: false,
              drawTicks: false,
              borderDash: [3, 4],
              zeroLineWidth: 1,
              zeroLineColor: this.getBaseColor("shape", 2),
              zeroLineBorderDash: [3, 4],
            },

            ticks: {
              display: true,
              beginAtZero: true,
              fontColor: this.getBaseColor("shape", 3),
              fontSize: 13,
              padding: 10,
            },
          }]
        },

        tooltips: {
           mode: "x",
           yPadding: 10,
           xPadding: 10,
           bodySpacing: 5,
           displayColors: false,
           backgroundColor: this.getStateColor("brand"),
        }
      };
    },
  },

  methods: {
    getBaseColor(type, level) {
      return this.layoutConfig("colors.custom.base")[type][level - 1];
    },

    getStateColor(name) {
      return this.layoutConfig("colors.custom.state")[name];
    },
  },

  mounted() {
    this.renderChart(this.chartData, this.options);
  },
};
