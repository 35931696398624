<template>
  <date-range-picker
    opens="left"
    single-date-picker="single"

    v-bind:locale-data="locale"

    v-bind:max-date="maxDate"

    v-bind:ranges="false"
    v-bind:auto-apply="true"

    v-model="dateRange"
  >
    <template v-slot:input>
      <a class="btn btn-sm btn-hover-light-primary btn-text-dark-75 font-weight-bold date-selector">
        <i class="flaticon2-calendar-1 date-selector-icon" />
        <span class="date-selector-prefix" v-text="prefix" />
        <span class="date-selector-date" v-text="formatted" />
      </a>
    </template>
  </date-range-picker>
</template>

<script>
import moment from "moment";

import DateRangePicker from "vue2-daterange-picker";

const locale = {
  format: "MM/DD/YYYY",

  separator: " - ",

  applyLabel:  "Salvează",
  cancelLabel: "Anulează",

  fromLabel: "De",
  toLabel:   "La",

  customRangeLabel: "Alege",

  weekLabel: "W",

  firstDay: 1,

  daysOfWeek: [
    "Du",
    "Lu",
    "Ma",
    "Mi",
    "Jo",
    "Vi",
    "Sâ"
  ],

  monthNames: [
    "Ianuarie",
    "Februarie",
    "Martie",
    "Aprilie",
    "Mai",
    "Iunie",
    "Iulie",
    "August",
    "Septembrie",
    "Octombrie",
    "Noiembrie",
    "Decembrie"
  ],
};

export default {
  components: {
    DateRangePicker,
  },

  props: {
    value: {
      type: moment,
      default: moment().startOf("day"),
    },
  },

  data() {
    return {
      date: this.value.clone().startOf("day"),

      dateRange: {
        startDate: this.value.toDate(),
        endDate:   this.value.toDate(),
      },

      maxDate: moment().startOf("day").toDate(),

      locale,
    };
  },

  computed: {
    prefix() {
      const today     = moment().startOf("day");
      const yesterday = today.clone().subtract(1, "days");

      if (this.date.isSame(today)) {
        return "Astăzi:";
      } else if (this.date.isSame(yesterday)) {
        return "Ieri:";
      } else {
        return "";
      }
    },

    formatted() {
      return this.date.format("MMM D");
    },
  },

  watch: {
    value(value) {
      this.date = value.startOf("day");
    },

    dateRange(range) {
      this.date = moment(range.startDate).startOf("day");
      this.$emit("input", this.date)
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .form-control {
    background: none;
    border: none;
    padding: 0;
    height: auto;
    width: auto;
  }

  .date-selector {
    .date-selector-icon {
      padding-right: 0.5rem;
    }

    .date-selector-prefix {
      padding-right: 0.35rem;

      &:empty {
        display: none;
      }
    }
  }
}
</style>
