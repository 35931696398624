<template>
  <div id="cancellation-reasons-daily" class="card card-custom card-stretch gutter-b">
    <div class="card-header border-0 py-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label font-weight-bolder text-dark">
          Comenzi anulate / zi
        </span>
      </h3>

      <div class="card-toolbar">
        <PortletDatePicker v-model="date" />
      </div>
    </div>

    <div class="card-body pt-0">
      <div class="row">
        <div class="col px-8 py-6 mr-8">
          <div class="font-size-lg text-dark-50 font-weight-bold">
            Total comenzi anulate
          </div>
          <div class="font-size-h4 font-weight-bolder" v-text="cancelledCount" />
        </div>
      </div>

      <CancellationReasonsChart
        v-bind:width="600"
        v-bind:height="250"
        v-bind:chart-data="chartData"
      />
    </div>

    <!--
      <div class="kt-portlet__body kt-portlet__body--fluid">
        <div class="kt-widget14">
          <div class="kt-widget14__header">
            <h3 class="kt-widget14__title">Total comenzi anulate</h3>
            <span class="kt-widget14__desc" data-bind="text: count"></span>
          </div>

          <div class="kt-widget14__content">
            <div class="kt-widget14__chart">
              <div id="cancellation-reasons-daily-chart"></div>
            </div>

            <div class="kt-widget14__legends" data-bind="foreach: reasons">
              <div class="kt-widget14__legend">
                <span class="kt-widget14__bullet kt-bg-success" data-bind="class: colorClass"></span>
                <span class="kt-widget14__stats" data-bind="text: count">+10% New York</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    -->
  </div>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";

import api from "@/core/services/api";

import CancellationReasonsChart from "../charts/CancellationReasons";
import PortletDatePicker from "@/view/content/widgets/PortletDatePicker"

export default {
  components: {
    CancellationReasonsChart,
    PortletDatePicker,
  },

  data() {
    return {
      date: null,

      chartData: {},
      cancelledCount: 0,
    };
  },

  computed: {
    ...mapGetters(["layoutConfig"]),
  },

  watch: {
    date: async function(newDate) {
      const data = await api.stats.getOrderCancellationReasons(newDate);

      this.processNewData(data);
    },
  },

  created() {
    this.date = moment().startOf("day");
  },

  methods: {
    processNewData(data) {
      this.chartData      = data;
      this.cancelledCount = Object.values(data).reduce((total, num) => total + num, 0);

      // let data = Object.entries(reasonDefs)
      //   .map(([reason, def]) => {
      //     return {
      //       colorClass: def.colorClass,
      //       colorHex: def.colorHex,
      //       label: def.label,
      //       count: response[reason]
      //     };
      //   })
      //   .filter(reason => reason.count > 0);
    },
  },
};
</script>

<style lang="scss">
#cancellation-reasons-daily {
  #cancellation-reasons-daily-chart {
    height: 250px;
    width: 250px;
  }

  .kt-widget14 {
    padding: 0;

    .kt-widget14__header {
      .kt-widget14__title {
        font-size: 1rem;
        color: kt-base-color(label, 2);
        padding-bottom: 0.5rem;
        font-weight: 500;
        display: block;
      }

      .kt-widget14__desc {
        font-size: 1.4rem;
        font-weight: 600;
        color: kt-base-color(label, 3);
        display: block;
      }
    }
  }

  .kt-portlet__head-toolbar {
    .btn {
      display: inline-flex;
      height: 32px !important;
      padding-top: 0;
      padding-bottom: 0;
      margin-top: 0.25rem;
      margin-bottom: 0.25rem;

      i {
        font-size: 1rem !important;
      }

      &.btn-icon {
        width: 32px !important;
      }

      &:not(.dropdown-toggle-split) {
        margin-left: 0.5rem;
      }
    }

    .date-selector {
      background: darken(#eaecf2, 3%);

      .date-selector-title {
        display: inline-block;
        color: #959cb6;
        font-weight: 500;
      }

      .date-selector-date {
        display: inline-block;
        color: kt-brand-color();
        margin-right: 0.75rem;
        font-weight: 500;
      }

      i {
        color: kt-brand-color();
      }


      &:hover,
      &.active,
      &:active,
      &:focus {
        background: darken(#eaecf2, 6%);

        i {
          color: kt-brand-color();
        }
      }
    }
  }
}
</style>
