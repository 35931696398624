<template>
  <DonutChart id="cancellation-reasons-daily-chart" resize="true" v-bind:data="reasons" />
</template>

<script>
import { mapGetters } from "vuex";
import { DonutChart } from "vue-morris";

export default {
  components: {
    DonutChart,
  },

  props: {
    chartData: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...mapGetters(["layoutConfig"]),

    reasons() {
      const defs = Object.entries(this.reasonDefs);

      const entries = defs.map(([reason, def]) => ({
        label: def.label,
        value: this.chartData[reason] || 0,
        color: def.colorHex,
      }));

      const data = entries.filter(entry => entry.value > 0);

      if (data.length === 0) {
        data.push({
          label: "Nu sunt\ncomenzi anulate",
          value: 1,
          color: this.getStateColor("success"),
        });
      }

      return data;
    },

    reasonDefs() {
      return {
        cancelledByCustomer: {
          colorClass: "kt-bg-success",
          colorHex: this.getStateColor("success"),
          label: "Anulată de\ncătre client",
        },

        carNotFound: {
          colorClass: "kt-bg-brand",
          colorHex: this.getStateColor("brand"),
          label: "Mașină\nindisponibilă",
        },

        addressIncomplete: {
          colorClass: "kt-bg-info",
          colorHex: this.getStateColor("info"),
          label: "Adresă\nincompletă",
        },

        customerDidNotArrive: {
          colorClass: "kt-bg-warning",
          colorHex: this.getStateColor("warning"),
          label: "Clientul nu\na coborât",
        },

        driverDidNotComplete: {
          colorClass: "kt-bg-danger",
          colorHex: this.getStateColor("danger"),
          label: "Șoferul nu a\nfinalizat comanda",
        },
      };
    },
  },

  methods: {
    getStateColor(name) {
      return this.layoutConfig("colors.custom.state")[name];
    },
  },
};
</script>
