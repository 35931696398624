var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-3"},[_c('div',{staticClass:"card card-custom bgi-no-repeat bgi-no-repeat bgi-size-cover gutter-b",staticStyle:{"height":"150px"},style:({
          backgroundImage: ("url(" + (_vm.backgroundImage('media/bg/bg-9.jpg')) + ")")
        })},[_vm._m(0)])]),_vm._m(1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-6"},[_c('div',{staticClass:"card card-custom bgi-no-repeat gutter-b",staticStyle:{"height":"175px","background-color":"#663259","background-position":"calc(100% + 0.5rem) 100%","background-size":"100% auto"},style:({
          backgroundImage: ("url(" + (_vm.backgroundImage(
            'media/svg/patterns/taieri.svg'
          )) + ")")
        })},[_vm._m(2)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-6"},[_c('div',{staticClass:"card card-custom bg-primary gutter-b",staticStyle:{"height":"150px"}},[_c('div',{staticClass:"card-body"},[_c('span',{staticClass:"svg-icon svg-icon-3x svg-icon-white ml-n2"},[_c('inline-svg',{attrs:{"src":"media/svg/icons/Layout/Layout-4-blocks.svg"}})],1),_c('div',{staticClass:"text-inverse-primary font-weight-bolder font-size-h2 mt-3"},[_vm._v(" 790 ")]),_c('a',{staticClass:"text-inverse-primary font-weight-bold font-size-lg mt-1",attrs:{"href":"#"}},[_vm._v("New Products")])])])]),_c('div',{staticClass:"col-xl-6"},[_c('div',{staticClass:"card card-custom gutter-b",staticStyle:{"height":"150px"}},[_c('div',{staticClass:"card-body"},[_c('span',{staticClass:"svg-icon svg-icon-3x svg-icon-success"},[_c('inline-svg',{attrs:{"src":"media/svg/icons/Communication/Group.svg"}})],1),_c('div',{staticClass:"text-dark font-weight-bolder font-size-h2 mt-3"},[_vm._v(" 8,600 ")]),_c('a',{staticClass:"text-muted text-hover-primary font-weight-bold font-size-lg mt-1",attrs:{"href":"#"}},[_vm._v("New Customers")])])])])])]),_c('div',{staticClass:"col-xl-6"},[_c('div',{staticClass:"card card-custom bgi-no-repeat bgi-size-cover gutter-b card-stretch",style:({
          backgroundImage: ("url(" + (_vm.backgroundImage(
            'media/stock-600x600/img-16.jpg'
          )) + ")")
        })},[_c('div',{staticClass:"card-body d-flex flex-column align-items-start justify-content-start"},[_vm._m(3),_c('a',{staticClass:"btn btn-link btn-link-warning font-weight-bold",attrs:{"href":"#"}},[_vm._v("Create Report "),_c('span',{staticClass:"svg-icon svg-icon-lg svg-icon-warning"},[_c('inline-svg',{attrs:{"src":"media/svg/icons/Navigation/Arrow-right.svg"}})],1)])])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card-body d-flex flex-column"},[_c('a',{staticClass:"text-dark-75 text-hover-primary font-weight-bolder font-size-h3",attrs:{"href":"#"}},[_vm._v("Properties")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-xl-9"},[_c('div',{staticClass:"card card-custom gutter-b",staticStyle:{"height":"150px"}},[_c('div',{staticClass:"card-body d-flex align-items-center justify-content-between flex-wrap"},[_c('div',{staticClass:"mr-2"},[_c('h3',{staticClass:"font-weight-bolder"},[_vm._v("Create CRM Reports")]),_c('div',{staticClass:"text-dark-50 font-size-lg mt-2"},[_vm._v(" Generate the latest CRM Report ")])]),_c('a',{staticClass:"btn btn-primary font-weight-bold py-3 px-6",attrs:{"href":"#"}},[_vm._v("Start Now")])])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card-body d-flex align-items-center"},[_c('div',[_c('h3',{staticClass:"text-white font-weight-bolder line-height-lg mb-5"},[_vm._v(" Create SaaS "),_c('br'),_vm._v("Based Reports ")]),_c('a',{staticClass:"btn btn-success font-weight-bold px-6 py-3",attrs:{"href":"#"}},[_vm._v("Create Report")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"p-1 flex-grow-1"},[_c('h3',{staticClass:"text-white font-weight-bolder line-height-lg mb-5"},[_vm._v(" Create Reports "),_c('br'),_vm._v("With App ")])])}]

export { render, staticRenderFns }