<template>
  <div id="orders-evolution-hourly" class="card card-custom card-stretch gutter-b">
    <div class="card-header border-0 py-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label font-weight-bolder text-dark">
          Evolutie comenzi / oră
        </span>
      </h3>

      <div class="card-toolbar">
        <PortletDatePicker v-model="date" />
      </div>
    </div>

    <div class="card-body pt-0">
      <div class="row">
        <div class="col px-8 py-6 mr-8">
          <div class="font-size-lg text-dark-50 font-weight-bold">
            Total comenzi
          </div>
          <div class="font-size-h4 font-weight-bolder" v-text="completed" />
        </div>

        <div class="col px-8 py-6">
          <div class="font-size-lg text-dark-50 font-weight-bold">
            Confirmate prin licitație
          </div>
          <div class="font-size-h4 font-weight-bolder" v-text="completedBidding" />
        </div>

        <div class="col px-8 py-6">
          <div class="font-size-lg text-dark-50 font-weight-bold">
            Confirmate prin dispecerat
          </div>
          <div class="font-size-h4 font-weight-bolder" v-text="completedDispatch" />
        </div>
      </div>

      <OrderEvolutionChart
        v-bind:width="600"
        v-bind:height="250"
        v-bind:chart-data="chartData"
      />
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";

import api from "@/core/services/api";

import OrderEvolutionChart from "../charts/OrderEvolution";
import PortletDatePicker from "@/view/content/widgets/PortletDatePicker"

export default {
  components: {
    OrderEvolutionChart,
    PortletDatePicker,
  },

  data() {
    return {
      date: null,

      cancelled: 0,
      completed: 0,

      completedBidding: 0,
      completedDispatch: 0,

      biddingData: [],
      dispatchData: [],
    };
  },

  computed: {
    ...mapGetters(["layoutConfig"]),

    chartData() {
      const hours  = [...Array(24).keys()];
      const labels = hours.map(hour => {
        if (hour == 0) {
          return "12 AM";
        } else if (hour < 12) {
          return `${hour} AM`;
        } else if (hour == 12) {
          return "12 PM";
        } else {
          return `${hour - 12} PM`;
        }
      });

      return {
        labels,
        datasets: [
          {
            label: "Licitație",
            backgroundColor: this.layoutConfig("colors.theme.base.success"),
            data: Object.freeze(this.biddingData),
          },
          {
            label: "Dispecerat",
            backgroundColor: this.layoutConfig("colors.theme.base.warning"),
            data: Object.freeze(this.dispatchData),
          }
        ],
      };
    },
  },

  watch: {
    date: async function(newDate) {
      const data = await api.stats.getOrderCountsByHour(newDate);

      this.processNewData(data);
    },
  },

  created() {
    this.date = moment().startOf("day");
  },

  methods: {
    processNewData(data) {
      const hours = [...Array(24).keys()];

      this.cancelled = 0;
      this.completed = 0;

      this.completedBidding  = 0;
      this.completedDispatch = 0;

      this.biddingData  = [];
      this.dispatchData = [];

      hours.forEach(hour => {
        if (data.length > hour) {
          const entry = data[hour];

          this.cancelled += entry.cancelled;
          this.completed += entry.completed;

          this.completedBidding  += entry.completedBidding;
          this.completedDispatch += entry.completedDispatch;

          this.biddingData.push(entry.completedBidding);
          this.dispatchData.push(entry.completedDispatch);
        } else {
          this.biddingData.push(0);
          this.dispatchData.push(0);
        }
      });
    },
  },
};
</script>

<style lang="scss">
#orders-evolution-hourly {
  .kt-portlet__head-toolbar {
    .btn {
      display: inline-flex;
      height: 32px !important;
      padding-top: 0;
      padding-bottom: 0;
      margin-top: 0.25rem;
      margin-bottom: 0.25rem;

      i {
        font-size: 1rem !important;
      }

      &.btn-icon {
        width: 32px !important;
      }

      &:not(.dropdown-toggle-split) {
        margin-left: 0.5rem;
      }
    }

    .date-selector {
      background: darken(#eaecf2, 3%);

      .date-selector-title {
        display: inline-block;
        color: #959cb6;
        font-weight: 500;
      }

      .date-selector-date {
        display: inline-block;
        color: kt-brand-color();
        margin-right: 0.75rem;
        font-weight: 500;
      }

      i {
        color: kt-brand-color();
      }


      &:hover,
      &.active,
      &:active,
      &:focus {
        background: darken(#eaecf2, 6%);

        i {
          color: kt-brand-color();
        }
      }
    }
  }
}
</style>
